
import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import ScoreRating from '@/modules/common/components/ui-kit/score-rating.vue';
import RankingModel from '@/modules/ranking/models/ranking-document.model';
import type HotelsService from '@/modules/hotels/hotels.service';
import type ProvidersService from '@/modules/providers/providers.service';
import ChainRankingBreakdown from './ranking-chain-breakdown.vue';

@Component({
    components: {
        ChainRankingBreakdown,
        ScoreRating,
    },
})
export default class RankingChainProvider extends Vue {
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    @Prop({ type: String, required: true })
    compsetId!: string;

    @Prop({ type: String, required: true })
    provider!: string;

    @Prop({ type: Number, required: false })
    hotelId?: number;

    @Prop({ type: Object, required: false })
    compsetMain?: RankingModel;

    readonly newReviews: number = 30;

    get rankingData() {
        if (!this.compsetMain) {
            return null;
        }
        if (!this.hotelId) {
            return null;
        }
        if (!this.compsetMain.providers[this.provider]) {
            return null;
        }
        if (!this.compsetMain.providers[this.provider][this.hotelId]) {
            return null;
        }
        return this.compsetMain.providers[this.provider][this.hotelId];
    }

    get maxScore() {
        return this.providersService.getMaxScore(this.provider) || 5;
    }

    get myHotelName(): string | null {
        if (!this.hotelId) {
            return null;
        }

        return this.hotelsService.hotelNames[this.hotelId];
    }
}
